import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Employer {
  getEmployerExcelList(
    id,
    name,
    organisation_id,
    department_ids,
    nationality_ids
  ) {
    return axios.get(
      `/api/admin/employer/employer-detail/list/excel`,
      {
        params: {
          id: id,
          name: name,
          organisation_id: organisation_id,
          department_ids: department_ids,
          nationality_ids: nationality_ids,
          page_num: 1000,
        },
      },
      config
    );
  }
  getEmployerInfo(id) {
    return axios.get(
      `/api/admin/employer/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getEmployerList(
    id,
    name,
    organisation_id,
    department_ids,
    nationality_ids,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/employer/list`,
      {
        params: {
          id: id,
          name: name,
          organisation_id: organisation_id,
          department_ids: department_ids,
          nationality_ids: nationality_ids,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getEmployerSelectList(name) {
    return axios.get(
      `/api/admin/employer/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  //   batchEmployer(datas) {
  //     return axios.post(
  //       `/api/admin/batch/employer`,
  //       {
  //         datas: datas,
  //       },
  //       config
  //     );
  //   }
  addEmployer(
    password,
    organisation_id,
    new_position_ids,
    new_work_permit_ids,
    new_currency_id,
    name,
    english_name,
    salary,
    role,
    role_addition_binding,
    receipt_type,
    special_department
  ) {
    return axios.post(
      `/api/admin/employer`,
      {
        special_department: special_department,
        password: password,
        organisation_id: organisation_id,
        new_position_ids: new_position_ids,
        new_work_permit_ids: new_work_permit_ids,
        new_currency_id: new_currency_id,
        name: name,
        english_name: english_name,
        salary: salary,
        role: role,
        role_addition_binding: role_addition_binding,
        receipt_type: receipt_type,
      },
      config
    );
  }
  editEmployer(
    id,
    password,
    organisation_id,
    new_position_ids,
    delete_position_ids,
    new_work_permit_ids,
    delete_work_permit_ids,
    new_currency_id,
    delete_currency_id,
    name,
    english_name,
    salary,
    role,
    role_addition_binding,
    receipt_type,
    special_department
  ) {
    return axios.put(
      `/api/admin/employer`,
      {
        special_department: special_department,
        id: id,
        password: password,
        organisation_id: organisation_id,
        new_position_ids: new_position_ids,
        delete_position_ids: delete_position_ids,
        new_work_permit_ids: new_work_permit_ids,
        delete_work_permit_ids: delete_work_permit_ids,
        new_currency_id: new_currency_id,
        delete_currency_id: delete_currency_id,
        name: name,
        english_name: english_name,
        salary: salary,
        role: role,
        role_addition_binding: role_addition_binding,
        receipt_type: receipt_type,
      },
      config
    );
  }
  deleteEmployer(id, status) {
    return axios.put(
      `/api/admin/employer`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
}
